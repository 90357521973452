import React, { useState, useEffect } from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { graphql } from "gatsby"
import { useIntl, injectIntl } from "gatsby-plugin-intl";
import Img from "gatsby-image";
import ServicesComp from '../components/servicesComp';
import ServiceForm from "../components/serviceForm";

import styled from 'styled-components';

const H1 = styled.h1`
    font-size: 30px;
    margin-bottom:35px;
    margin-top:25px;
}`;

const H2 = styled.h2`
    font-size: 22px;
    color: blue;
    text-decoration: underline;
    cursor: pointer;
    margin-top: 10px;
}`;

const H3 = styled.h3`
    font-size: 20px;
    margin-top: 20px;
    margin-bottom:8px;
}`;

const TextJustified = styled.div`
    text-align: justify;
}`;

const P = styled.p`
    margin-top: 5px;
    color: black;
    font-size: inherit;
}`;

const LI = styled.li`
    margin-left: 20px;
    margin-bottom: 0px;
}`;

const ServiceTaxAdministration = ({ data }) => {
    const intl = useIntl();
    const [showContent1, setShowContent1] = useState(false);
    const [showContent2, setShowContent2] = useState(false);
    const [showContent3, setShowContent3] = useState(false);
    const [showContent4, setShowContent4] = useState(false);

    const setShows = () => {
        setShowContent1(false);
        setShowContent2(false);
        setShowContent3(false);
        setShowContent4(false);
    }

    const myHeaderRef = React.createRef();
    
    useEffect(() => {
        const url = window.location.href;
        const section = url.split('#')[1];
        
        setTimeout(() => {
            window.scrollTo(0, myHeaderRef.current.offsetTop);
            section === 'administration' && setShowContent1(true);
        }, 100);
    }, [])

    return (
        <Layout>
            <SEO title={intl.formatMessage({ id: 'Service2Title' })} />

            <section>
                <ServicesComp intl={intl}></ServicesComp>

                <section className="page-section blog-page">
                    <div className="container">                             
                        <TextJustified className="row" ref={myHeaderRef}>
                            <H1><strong>{intl.formatMessage({ id: 'Service2Title' })}</strong></H1>
                        </TextJustified>
                            
                        <TextJustified className="row">
                            <H2 onClick={() => { setShows(); setShowContent1(!showContent1) } }>{intl.formatMessage({ id: 'service2t1' })}</H2>
                            <P>{intl.formatMessage({ id: 'service2p1' })}</P>
                        </TextJustified>

                        {showContent1 &&
                            <div className="row">

                                <div className="col-lg-7">
                                    <TextJustified className="row">
                                        <H3>{intl.formatMessage({ id: 'Service2Section1p1' })}</H3>
                                        <ul>
                                            <LI>{intl.formatMessage({ id: 'Service2Section1p1List1' })}</LI>
                                            <LI>{intl.formatMessage({ id: 'Service2Section1p1List2' })}</LI>
                                            <LI>{intl.formatMessage({ id: 'Service2Section1p1List3' })}</LI>
                                            <LI>{intl.formatMessage({ id: 'Service2Section1p1List4' })}</LI>
                                            <LI>{intl.formatMessage({ id: 'Service2Section1p1List5' })}</LI>
                                        </ul>
                                    </TextJustified>
                                    <TextJustified className="row">
                                        <H3>{intl.formatMessage({ id: 'Service2Section1p2' })}</H3>
                                        <ul>
                                            <LI>{intl.formatMessage({ id: 'Service2Section1p2List1' })}</LI>
                                            <LI>{intl.formatMessage({ id: 'Service2Section1p2List2' })}</LI>
                                            <LI>{intl.formatMessage({ id: 'Service2Section1p2List3' })}</LI>
                                            <LI>{intl.formatMessage({ id: 'Service2Section1p2List4' })}</LI>
                                            <LI>{intl.formatMessage({ id: 'Service2Section1p2List5' })}</LI>
                                            <LI>{intl.formatMessage({ id: 'Service2Section1p2List6' })}</LI>
                                        </ul>
                                    </TextJustified>
                                    <TextJustified className="row">
                                        <H3>{intl.formatMessage({ id: 'Service2Section1p3' })}</H3>
                                        <ul>
                                            <LI>{intl.formatMessage({ id: 'Service2Section1p3List1' })}</LI>
                                            <LI>{intl.formatMessage({ id: 'Service2Section1p3List2' })}</LI>
                                            <LI>{intl.formatMessage({ id: 'Service2Section1p3List3' })}</LI>
                                            <LI>{intl.formatMessage({ id: 'Service2Section1p3List4' })}</LI>
                                            <LI>{intl.formatMessage({ id: 'Service2Section1p3List5' })}</LI>
                                            <LI>{intl.formatMessage({ id: 'Service2Section1p3List6' })}</LI>
                                            <LI>{intl.formatMessage({ id: 'Service2Section1p3List7' })}</LI>
                                        </ul>
                                    </TextJustified>
                                    <TextJustified className="row">
                                        <H3>{intl.formatMessage({ id: 'Service2Section1p4' })}</H3>
                                        <ul>
                                            <LI>{intl.formatMessage({ id: 'Service2Section1p4List1' })}</LI>
                                        </ul>
                                    </TextJustified>
                                    <TextJustified className="row">
                                        <P>{intl.formatMessage({ id: 'Service2Section1p5' })}</P>
                                    </TextJustified>
                                    <br/>
                                </div>

                                <div className="col-lg-1"></div>

                                <div className="col-lg-4">
                                    <ServiceForm intl={intl} />
                                </div>
                            </div>
                        }
                            
                        <TextJustified className="row">
                            <H2 onClick={() => { setShows(); setShowContent2(!showContent2) } }>{intl.formatMessage({ id: 'service2t2' })}</H2>
                            <P>{intl.formatMessage({ id: 'service2p2' })}</P>
                        </TextJustified>

                        {showContent2 &&
                            <div className="row">
                                <div className="col-lg-7">
                                    <TextJustified className="row">
                                        <H3>{intl.formatMessage({ id: 'Service2Section2p1' })}</H3>
                                        <ul>
                                            <LI>{intl.formatMessage({ id: 'Service2Section2p1List1' })}</LI>
                                            <LI>{intl.formatMessage({ id: 'Service2Section2p1List2' })}</LI>
                                        </ul>
                                    </TextJustified>
                                    <TextJustified className="row">
                                        <H3>{intl.formatMessage({ id: 'Service2Section2p2' })}</H3>
                                        <ul>
                                            <LI>{intl.formatMessage({ id: 'Service2Section2p2List1' })}</LI>
                                            <LI>{intl.formatMessage({ id: 'Service2Section2p2List2' })}</LI>
                                            <LI>{intl.formatMessage({ id: 'Service2Section2p2List3' })}</LI>
                                            <LI>{intl.formatMessage({ id: 'Service2Section2p2List4' })}</LI>
                                        </ul>
                                    </TextJustified>
                                    <TextJustified className="row">
                                        <H3>{intl.formatMessage({ id: 'Service2Section2p3' })}</H3>
                                        <ul>
                                            <LI>{intl.formatMessage({ id: 'Service2Section2p3List1' })}</LI>
                                            <LI>{intl.formatMessage({ id: 'Service2Section2p3List2' })}</LI>
                                            <LI>{intl.formatMessage({ id: 'Service2Section2p3List3' })}</LI>
                                            <LI>{intl.formatMessage({ id: 'Service2Section2p3List4' })}</LI>
                                        </ul>
                                    </TextJustified>
                                    <br/>
                                </div>
                                
                                <div className="col-lg-1"></div>

                                <div className="col-lg-4">
                                    <ServiceForm intl={intl} />
                                </div>
                            </div>
                        }
                            
                        <TextJustified className="row">
                            <H2 onClick={() => { setShows(); setShowContent3(!showContent3) } }>{intl.formatMessage({ id: 'service2t3' })}</H2>
                            <P>{intl.formatMessage({ id: 'service2p3' })}</P>
                        </TextJustified>

                        {showContent3 &&
                            <div className="row">
                                <div className="col-lg-7">
                                    <TextJustified className="row">
                                        <H3>{intl.formatMessage({ id: 'Service2Section3p1' })}</H3>
                                        <ul>
                                            <LI>{intl.formatMessage({ id: 'Service2Section3p1List1' })}</LI>
                                            <LI>{intl.formatMessage({ id: 'Service2Section3p1List2' })}</LI>
                                            <LI>{intl.formatMessage({ id: 'Service2Section3p1List3' })}</LI>
                                            <LI>{intl.formatMessage({ id: 'Service2Section3p1List4' })}</LI>
                                            <LI>{intl.formatMessage({ id: 'Service2Section3p1List5' })}</LI>
                                            <LI>{intl.formatMessage({ id: 'Service2Section3p1List6' })}</LI>
                                            <LI>{intl.formatMessage({ id: 'Service2Section3p1List7' })}</LI>
                                        </ul>
                                    </TextJustified>
                                    <TextJustified className="row">
                                        <H3>{intl.formatMessage({ id: 'Service2Section3p2' })}</H3>
                                        <ul>
                                            <LI>{intl.formatMessage({ id: 'Service2Section3p2List1' })}</LI>
                                            <LI>{intl.formatMessage({ id: 'Service2Section3p2List2' })}</LI>
                                            <LI>{intl.formatMessage({ id: 'Service2Section3p2List3' })}</LI>
                                            <LI>{intl.formatMessage({ id: 'Service2Section3p2List4' })}</LI>
                                            <LI>{intl.formatMessage({ id: 'Service2Section3p2List5' })}</LI>
                                            <LI>{intl.formatMessage({ id: 'Service2Section3p2List6' })}</LI>
                                        </ul>
                                    </TextJustified>
                                    <TextJustified className="row">
                                        <H3>{intl.formatMessage({ id: 'Service2Section3p3' })}</H3>
                                        <ul>
                                            <LI>{intl.formatMessage({ id: 'Service2Section3p3List1' })}</LI>
                                            <LI>{intl.formatMessage({ id: 'Service2Section3p3List2' })}</LI>
                                            <LI>{intl.formatMessage({ id: 'Service2Section3p3List3' })}</LI>
                                            <LI>{intl.formatMessage({ id: 'Service2Section3p3List4' })}</LI>
                                        </ul>
                                    </TextJustified>
                                    <br/>
                                </div>
                                
                                <div className="col-lg-1"></div>

                                <div className="col-lg-4">
                                    <ServiceForm intl={intl} />
                                </div>
                            </div>
                        }
                            
                        {intl.locale !== 'es' && 
                            <TextJustified className="row">
                                <H2 onClick={() => { setShows(); setShowContent4(!showContent4) } }>{intl.formatMessage({ id: 'service2t4' })}</H2>
                                <P>{intl.formatMessage({ id: 'service2p4' })}</P>
                            </TextJustified>
                        }

                        {showContent4 &&
                            <div className="row">
                                <div className="col-lg-7">
                                    <TextJustified className="row">
                                        <H3>{intl.formatMessage({ id: 'Service2Section4p1' })}</H3>
                                        <ul>
                                            <LI>{intl.formatMessage({ id: 'Service2Section4p1List1' })}</LI>
                                            <LI>{intl.formatMessage({ id: 'Service2Section4p1List2' })}</LI>
                                            <LI>{intl.formatMessage({ id: 'Service2Section4p1List3' })}</LI>
                                            <LI>{intl.formatMessage({ id: 'Service2Section4p1List4' })}</LI>
                                        </ul>
                                    </TextJustified>
                                    <TextJustified className="row">
                                        <H3>{intl.formatMessage({ id: 'Service2Section4p2' })}</H3>
                                        <P>{intl.formatMessage({ id: 'Service2Section4p2subp' })}</P>
                                        <ul>
                                            <LI>{intl.formatMessage({ id: 'Service2Section4p2List1' })}</LI>
                                            <LI>{intl.formatMessage({ id: 'Service2Section4p2List2' })}</LI>
                                            <LI>{intl.formatMessage({ id: 'Service2Section4p2List3' })}</LI>
                                        </ul>
                                    </TextJustified>
                                    <TextJustified className="row">
                                        <P>
                                            {intl.formatMessage({ id: 'Service2Section4p3' })}
                                            <br/>
                                            {intl.formatMessage({ id: 'Service2Section4p4' })}
                                        </P>
                                    </TextJustified>
                                </div>
                                
                                <div className="col-lg-1"></div>

                                <div className="col-lg-4">
                                    <ServiceForm intl={intl} />
                                </div>
                            </div>
                        }

                        <br/>

                        <TextJustified className="row">
                            <Img className="detailIcon" fixed={data.alertIcon.childImageSharp.fixed} /><P>{intl.formatMessage({ id: 'Service1footer' })}</P>
                        </TextJustified>
                    </div>
                </section>
            </section>

        </Layout>
    )
}

export default injectIntl(ServiceTaxAdministration)
export const query = graphql`
query {
    alertIcon: file(relativePath: { eq: "alert.png" }) {
        childImageSharp {
            fixed(width: 30) {
                ...GatsbyImageSharpFixed
            }
        }
    },
}
`